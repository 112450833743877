import request from "@/api/config.js";

export function createJobCandidateReq(data) {
  return request({
    url: `job-candidate-request/create`,
    method: "post",
    data,
  });
}

export function getAllJobCandidateReq() {
  return request({
    url: `job-candidate-request/`,
    method: "get",
  });
}

export function getLastQueryByUser(user_id) {
  return request({
    url: `job-candidate-request/getLastQuery/by/user/${user_id}`,
    method: "get",
  });
}
